import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LatestUserData from "../action/LatestUserData";

function Wallet() {
    const user_data = LatestUserData();
    const Wallet = useSelector(state => state.Wallet);
    const Win_Wallet = useSelector(state => state.WinWallet);
    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            <div className="p-4">
                <Link className="d-flex align-items-center profile-wallet undefined"
                    to="/transaction-history">
                    <picture className="ml-4">
                        <img width="32px" src="/assets/images/order-history.png" alt="order-history" />
                    </picture>
                    <div className="ml-5 mytext text-muted ">Transaction History</div>
                </Link>
            </div>
            <div className="divider-x"></div>
            <div className="p-4">
                <div className="wallet-card" style={{ "backgroundColor": "rgb(71, 130, 244)", "backgroundImage": "url(/assets/images/bg-wallets-deposit.png)" }}>
                    <div className="d-flex align-items-center">
                        <picture className="mr-1">
                            <img height="26px" width="26px" src="/assets/images/global-rupeeIcon.png" alt="global-rupeeIcon" />
                        </picture>
                        <span className="text-white" style={{ "fontSize": "1.3em", "fontWeight": "900" }}>₹{Wallet}</span>
                    </div>
                    <div className="text-white text-uppercase" style={{ "fontSize": "0.9em", "fontWeight": "500" }}>Deposit Cash
                    </div>
                    <div className="mt-5" style={{ "fontSize": "0.9em", "color": "rgb(191, 211, 255)" }}>
                        Can be used to play Tournaments &amp; Battles.<br />Cannot be withdrawn to Paytm or Bank.
                    </div>
                    <Link to="/add-fund" className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase">
                        Add Cash
                    </Link>
                </div>
                <div className="wallet-card"
                    style={{ "backgroundColor": "rgb(127, 153, 255)", "backgroundImage": "url(/assets/images/bg-wallets-winnings.png)" }}>
                    <div className="d-flex align-items-center">
                        <picture className="mr-1">
                            <img height="26px" width="26px" src="/assets/images/global-rupeeIcon.png" alt="global-rupeeIcon" />
                        </picture>
                        <span className="text-white" style={{ "fontSize": "1.3em", "fontWeight": "900" }}>₹{Win_Wallet}</span>
                    </div>
                    <div className="text-white text-uppercase" style={{ "fontSize": "0.9em", "fontWeight": "500" }}>Winnings Cash
                    </div>
                    <div className="mt-5" style={{ "fontSize": "0.9em", "color": "rgb(216, 224, 255)" }}>
                        Can be withdrawn to Paytm or Bank. Can be used to play Tournaments &amp; Battles.
                    </div>
                    <Link to="/withdraw" className="walletCard-btn d-flex justify-content-center align-items-center text-uppercase">
                        Withdraw
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Wallet;